/* General Footer Styling */
.footer {
  background-color: #101010;
  height: auto;
  color: #ffffff; /* Set all text to white */
  padding: 60px 30px;
  font-family: 'Open Sans', sans-serif;
}

.footer h4, .footer p {
  margin: 0;
  color: #ffffff; /* Set headings and paragraphs to white */
}

/* Newsletter Section */
.newsletter-section {
  text-align: center;
  margin-bottom: 40px;
}

.newsletter-section h3 {
  font-size: 2.2rem;
  color: #ffffff; /* White text for the newsletter heading */
  margin-bottom: 20px;
}

.newsletter-form {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.newsletter-form input[type="email"] {
  padding: 12px 15px;
  border: none;
  border-radius: 25px;
  width: 300px;
  font-size: 1rem;
  color: #333;
  background-color: #f0f0f0;
}

.newsletter-form button {
  padding: 12px 20px;
  background-color: #0040a9; /* Dark blue for button background */
  color: #ffffff; /* White text for button */
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.newsletter-form button:hover {
  background-color: #04a5e9; /* Light blue on hover */
}

.required-field {
  color: #d32f2f;
  margin-top: 10px;
  font-size: 0.9rem;
}

/* Footer Main Content */
.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
}

.footer-branding {
  flex: 1;
  max-width: 300px;
}

.footer-branding img {
  width: 120px;
  margin-bottom: 15px;
}

.footer-branding p {
  font-size: 0.95rem;
  color: #ffffff; /* White text for branding paragraph */
  margin-bottom: 10px;
}

.footer-branding p:last-child {
  font-size: 0.85rem;
  color: #ffffff; /* White text for branding details */
}

/* Footer Links Styling */
.footer-links {
  display: flex;
  justify-content: space-around;
  gap: 40px;
}

.footer-links div {
  flex: 1;
}

.footer-links h4 {
  font-size: 1.2rem;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #ffffff; /* White text for link headers */
}

.footer-links ul {
  list-style: none;
  padding: 0;
  color: #ffffff; /* White text for link lists */
}

.footer-links ul li {
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.footer-links ul li a {
  color: #ffffff; /* White text for links */
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links ul li a:hover {
  color: #04a5e9; /* Light blue on hover */
}

.footer-links ul li::before {
  content: '• ';
  color: #ffffff; /* White bullet points */
  font-weight: bold;
}

/* Footer News Section */
.footer-news ul li {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 15px;
}

.footer-news ul li img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 10px;
}

.footer-news ul li span {
  font-size: 0.9rem;
  color: #ffffff; /* White text for news section */
}

.footer-news ul li time {
  font-size: 0.8rem;
  color: #ffffff; /* White text for time stamps */
  display: block;
  margin-top: 5px;
}

/* Footer Bottom Section */
.footer-bottom {
  text-align: center;
  border-top: 1px solid #444;
  padding-top: 25px;
  margin-top: 40px;
}

.footer-bottom p {
  font-size: 0.85rem;
  color: #ffffff; /* White text for bottom section */
}

/* Footer Bottom Links */
.footer-bottom a {
  color: #ffffff; /* White text for bottom links */
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-bottom a:hover {
  color: #04a5e9; /* Light blue on hover */
}

/* Social Media Icons */
.footer-branding-icons {
  margin-top: 20px;
}

.footer-branding-icons a {
  margin-right: 10px;
  color: #ffffff; /* White for social media icons */
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.footer-branding-icons a:hover {
  color: #04a5e9; /* Light blue on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-links {
    flex-direction: column;
    text-align: center;
  }

  .footer-links div {
    margin-bottom: 30px;
  }

  .footer-news ul {
    text-align: center;
  }

  .newsletter-form {
    flex-direction: column;
  }

  .newsletter-form input[type="email"],
  .newsletter-form button {
    width: 100%;
    margin-top: 10px;
  }
}
