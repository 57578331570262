.uni-list-container {
    padding: 20px 20px;
    background-color: #f8f9fa;
    text-align: center;
  }
/*   
  .uni-list-title {
    margin-bottom: 30px;
    font-size: 2.5em;
    color: #343a40;
    font-weight: bold;
  }
   */
  /* .uni-list-description {
    margin-bottom: 40px;
    font-size: 1.1em;
    color: #6c757d;
    max-width: 800px;
    margin: 0 auto;
  } */
  
  .uni-list-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .uni-list-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .uni-list-image-container {
    padding-top: 30px;
    width: 300px;
    height: 100px;
    /* overflow: hidden; */
  }
  
  .uni-list-image {
    max-width: 100%;
    height: auto;
    
  }
  
  .uni-list-card-title {
    padding: 10px;
    font-size: 1.1em;
    color: #007bff;
    font-weight: bold;
  }
  
  .uni-list-more-universities {
    margin-top: 20px;
    font-size: 1.1em;
    color: #495057;
    font-weight: bold;
  }

.uni-list-title {
    font-size: 2.5em; /* Larger font size for emphasis */
    font-weight: bold; /* Bold for impact */
    color: #2c3e50; /* Darker color for better readability */
    margin-bottom: 20px; /* Space below the title */
    text-align: center; /* Center-align the title */
    text-transform: uppercase; /* Optional: uppercase for emphasis */
    letter-spacing: 1px; /* Increase spacing between letters */
}

.uni-list-description {
    font-size: 1.1em; /* Font size for description */
    color: #34495e; /* Slightly lighter gray for text */
    line-height: 1.6; /* Improve readability with increased line height */
    margin-bottom: 20px; /* Space below the paragraph */
    text-align: justify; /* Justified text for a clean look */
}

/* Optional: Responsive adjustments */
@media (max-width: 768px) {
    .uni-list-container {
        padding: 20px; /* Reduced padding on smaller screens */
    }

    .uni-list-title {
        font-size: 2em; /* Smaller title on mobile */
    }

    .uni-list-description {
        font-size: 1em; /* Adjust description font size */
    }
}
