/* Section styling */
.feedback-section {
  text-align: center;
  padding: 50px 20px;
  background-color: #f8f8f8;
  position: relative; /* Allows positioning of the buttons */
}

.feedback-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #333;
}

.feedback-subtitle {
  font-size: 1.1rem;
  color: #888;
  margin-bottom: 40px;
}

/* Card grid styling */
.feedback-cards {
  display: flex;
  flex-wrap: nowrap; /* Prevents wrapping for horizontal scrolling */
  overflow: hidden; /* Hide overflow to maintain a clean look */
  width: 100%; /* Full width of the screen */
  padding-bottom: 20px;
}

/* Individual feedback card styling */
.feedback-card {
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  flex: 0 0 33.33%; /* Each card takes up one-third of the container width */
  transition: transform 0.3s ease;
  text-align: left;
}

.feedback-card:hover {
  transform: translateY(-10px);
}

/* Stars and feedback text styling */
.feedback-stars {
  color: #b00;
  font-size: 1.2rem;
}

.feedback-text {
  font-size: 1rem;
  color: #555;
  margin: 15px 0;
}

/* Person information styling */
.feedback-person {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.person-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.person-name {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
}

.person-role {
  font-size: 0.9rem;
  color: #888;
}

/* Quote icon styling */
.feedback-quote {
  font-size: 2rem;
  color: #b00;
}

/* Scroll Button Styling */
.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #04a5e9;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  transition: background-color 0.3s;
  z-index: 1; /* Ensures buttons are on top */
}

.scroll-button:hover {
  background-color: #0040a9;
}

.scroll-button.left {
  left: 10px; /* Aligns the left button */
}

.scroll-button.right {
  right: 10px; /* Aligns the right button */
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .feedback-title {
    font-size: 2rem;
  }

  .feedback-subtitle {
    font-size: 1rem;
  }

  .feedback-card {
    padding: 15px;
    flex: 0 0 100%; /* For smaller screens, make each card full width */
  }

  .feedback-text {
    font-size: 0.9rem;
  }

  .feedback-stars {
    font-size: 1rem;
  }

  .person-image {
    width: 40px;
    height: 40px;
  }

  .person-name {
    font-size: 1rem;
  }

  .person-role {
    font-size: 0.85rem;
  }

  .feedback-quote {
    font-size: 1.8rem;
  }

  .scroll-button {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .feedback-title {
    font-size: 1.8rem;
  }

  .feedback-subtitle {
    font-size: 0.9rem;
    margin-bottom: 30px;
  }

  .feedback-card {
    padding: 10px;
  }

  .feedback-text {
    font-size: 0.85rem;
  }

  .feedback-stars {
    font-size: 0.9rem;
  }

  .person-image {
    width: 35px;
    height: 35px;
  }

  .person-name {
    font-size: 0.9rem;
  }

  .person-role {
    font-size: 0.8rem;
  }

  .feedback-quote {
    font-size: 1.5rem;
  }

  .scroll-button {
    padding: 6px;
  }
}
