.program-section {
  padding: 40px;
  background-color: #f4f4f4;
}

.section-title {
  font-size: 2em;
  text-align: center;
  margin-bottom: 40px;
  color: #333;
}

.programs-container {
  display: flex;
  justify-content: space-between;
}

.program-box {
  flex: 1; /* Ensures equal width for each box */
  margin: 0 20px;
  position: relative; /* To position the image inside the container */
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
  overflow: hidden; /* Ensures that the image doesn't overflow the box */
  cursor: pointer;
}

.program-box h3 {
  font-size: 1.5em;
  margin-bottom: 20px;
  z-index: 2; /* Keeps the heading above the image */
}

.program-item {
  padding: 10px;
  margin-bottom: 20px;
  background-color: transparent;
  border-radius: 8px;
  z-index: 2; /* Ensures text stays above the image */
  position: relative;
  transition: background-color 0.3s ease; /* Smooth transition for hover */
}

.program-item span {
  float: right;
}

/* Hover effect for individual items */
.program-item:hover {
  background-color: #dff2e1; /* Change the color on hover */
}

/* Hidden image initially, will appear on hover */
.program-box-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 1; /* Image appears behind the text and content */
}

.program-box:hover .program-box-image {
  opacity: 0.3; /* Image becomes visible on hover */
}

/* Hover effect for the box itself */
.program-box:hover {
  transform: scale(1.05); /* Slightly enlarges the container on hover */
}

.undergraduate {
  background-color: whitesmoke;
}

.graduate {
  background-color: whitesmoke;
}

.lifelong-learning {
  background-color: whitesmoke;
}

/* Specific hover colors for each box */
.undergraduate .program-item:hover {
  background-color: #0040a9; /* Dark blue on hover for undergraduate */
  color: #dff2e1;
}

.graduate .program-item:hover {
  background-color: #0040a9; /* Dark blue on hover for graduate */
  color: #dff2e1;
}

.lifelong-learning .program-item:hover {
  background-color: #0040a9; /* Dark blue on hover for lifelong learning */
  color: #dff2e1;
}
.academic-overview {
  max-width: auto; /* Set a max width for the overview */
  margin: 20px auto; /* Center the overview on the page */
  padding: 20px; /* Add some padding for better spacing */
  background-color: #f9f9f9; /* Light background for contrast */
  border: 1px solid #ddd; /* Light border for definition */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.academic-overview h3 {
  text-align: center; /* Center the heading */
  font-size: 2em; /* Increase font size */
  color: #333; /* Darker color for the heading */
  margin-bottom: 20px; /* Space below the heading */
}

.academic-overview ul {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
}

.academic-overview li {
  background-color: #ffffff; /* White background for list items */
  border: 1px solid #ddd; /* Light border for list items */
  border-radius: 5px; /* Rounded corners for list items */
  margin-bottom: 15px; /* Space between list items */
  padding: 15px; /* Add padding for content */
  transition: transform 0.2s; /* Smooth hover effect */
}

.academic-overview li:hover {
  transform: scale(1.02); /* Slightly enlarge the item on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

.academic-overview strong {
  font-size: 1.2em; /* Slightly larger font for strong text */
  color: #007bff; /* Use a primary color for emphasis */
  display: block; /* Make strong text a block for spacing */
  margin-bottom: 8px; /* Space below strong text */
}


@media (max-width: 768px) {
  .programs-container {
    flex-direction: column;
  }

  .program-box {
    margin-bottom: 40px;
  }
}
