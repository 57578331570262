:root {
  --primary-color: #0040a9; /* Dark blue */
  --secondary-color: #ffffff; /* Light blue */
  --text-color: #333;
  --border-color: #ddd;
  --hover-opacity: 0.9;
  --transition-speed: 0.3s;
  --header-height: 60px;
  --spacing-unit: 1rem;
  --font-family: 'Poppins', sans-serif;
  --font-size-large: 1.5rem;
  --font-size-medium: 1.2rem;
  --font-size-small: 1rem;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  background-color: #fafafa;
  color: var(--text-color);
}

/* Sticky Header with Smooth Transition */
.uni-navbar-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: var(--secondary-color);
  transition: transform var(--transition-speed) ease-in-out, box-shadow var(--transition-speed) ease-in-out;
  width: 100%;
  padding: 0;
}

/* Navbar */
.uni-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
  width: 100%;
  padding: 0;
}

/* Logo */
.uni-navbar-logo {
  display: flex;
  align-items: center;
  gap: var(--spacing-unit);
}

.uni-navbar-logo img {
  height: 40px;
  width: auto;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.uni-navbar-logo img:hover {
  transform: scale(1.05);
}

.uni-navbar-logo span {
  font-size: var(--font-size-large);
  font-weight: 700;
  color: var(--primary-color);
  letter-spacing: -0.5px;
  transition: color var(--transition-speed) ease;
}

/* Navbar Links */
.uni-navbar-links {
  display: flex;
  gap: calc(var(--spacing-unit) * 1);
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 22px;
}

.uni-navbar-links li {
  position: relative;
}

.uni-navbar-links a {
  text-decoration: none;
  font-size: var(--font-size-medium);
  color: var(--text-color);
  transition: color var(--transition-speed) ease-in-out, transform 0.2s ease-in-out, text-decoration var(--transition-speed) ease-in-out;
  padding: 0.5rem;
}

.uni-navbar-links a:hover {
  color: var(--primary-color);
  transform: translateY(-2px);
  text-decoration: underline;
}

/* Dropdown Styles */
.uni-dropdown {
  position: relative;
}

.dropdown-toggle {
  background: transparent;
  border: none;
  font-size: var(--font-size-medium);
  color: var(--text-color);
  cursor: pointer;
  padding: 0.5rem;
  transition: color var(--transition-speed) ease;
  margin-top: -8px;
}

.dropdown-toggle:hover {
  color: var(--primary-color);
  margin-top: -7px;
}

.uni-dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--secondary-color);
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 4px;
  width: auto;
}

.uni-dropdown-menu li {
  padding: 0.5rem 1rem;
}

.uni-dropdown-menu a {
  text-decoration: none;
  color: var(--text-color);
  transition: background-color var(--transition-speed) ease;
  display: block;
}

.uni-dropdown-menu a:hover {
  background-color: rgba(0, 64, 169, 0.1);
}

/* Show dropdown on click */
.uni-dropdown .uni-dropdown-menu {
  display: block;
}
.profile-icon-button {
  background-color: #0040a9; /* Button color */
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%; /* Makes it circular */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.profile-icon-button:hover {
  background-color: #0056b3; /* Darker on hover */
}

.profile-icon-button i {
  font-size: 20px; /* Icon size */
}


/* Toggle Button */
.uni-navbar-toggle-btn {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 2000;
  transition: transform var(--transition-speed) ease;
}

.bar {
  height: 3px;
  width: 100%;
  background-color: var(--text-color);
  transition: all var(--transition-speed) ease-in-out;
}

.uni-navbar-toggle-btn:hover .bar {
  background-color: var(--primary-color);
}

/* Animation for active state */
.uni-navbar-toggle-btn .bar.active:nth-child(1) {
  transform: translateY(10px) rotate(45deg);
}

.uni-navbar-toggle-btn .bar.active:nth-child(2) {
  opacity: 0;
}

.uni-navbar-toggle-btn .bar.active:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
}

/* Search and Apply Button Controls */
.uni-navbar-search-lang-toggle {
  display: flex;
  align-items: center;
  gap: var(--spacing-unit);
  padding-right: var(--spacing-unit);
}

.uni-navbar-search-lang-toggle input {
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  outline: none;
  transition: border-color var(--transition-speed) ease-in-out, box-shadow 0.2s ease;
}

.uni-navbar-search-lang-toggle input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 4px rgba(0, 64, 169, 0.2);
}

/* Apply Button */
.apply-button {
  padding: 0.5rem 1rem;
  border: none;
  background-color: var(--primary-color);
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color var(--transition-speed) ease, transform var(--transition-speed) ease;
}

.apply-button:hover {
  background-color: #003080;
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .uni-navbar-toggle-btn {
    display: flex;
  }

  .uni-navbar-links {
    display: none;
  }

  .uni-navbar-links-mobile {
      display: flex;
      position: absolute;
      text-decoration: none;
      top: var(--header-height);
      left: 0;
      width: 100%;
      background-color: var(--secondary-color);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      padding: calc(var(--spacing-unit) * 2) 0; /* Increased padding for better spacing */
      flex-direction: column;
      align-items: center;
      gap: calc(var(--spacing-unit) * 1.5); 
      z-index: 100; /* Added z-index for proper stacking */
      opacity: 0;
      animation: slideDown 0.4s ease forwards; /* Added `forwards` to keep the animation end state */
  
  }
  li::marker {
    content: ""; /* Hides the marker */
}
ul.custom-list {
    padding-left: 0;
    list-style-type: none; /* Removes default bullets */
}

ul.custom-list li a {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    color: var(--primary-color); /* Custom color */
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

ul.custom-list li a:hover {
    background-color: var(--secondary-color); /* Background on hover */
    color: var(--hover-text-color); /* Text color on hover */
}

ul.custom-list li a:active {
    background-color: var(--active-bg-color); /* Background on active */
    color: var(--active-text-color); /* Text color on active */
}


  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .uni-navbar-search-lang-toggle {
    margin-left: 0;
  }

  .uni-navbar-search-lang-toggle input {
    display: none;
  }
}

@media (max-width: 768px) {
  :root {
    --header-height: 50px;
    text-decoration: none;
  }

  .uni-navbar-logo img {
    height: 30px;
  }

  .uni-navbar-logo span {
    font-size: var(--font-size-medium);
  }

  .uni-navbar-search-lang-toggle input {
    display: none;
  }

  .apply-button {
    padding: 0.3rem 0.5rem;
  }

  /* Align Toggle Button */
  .uni-navbar-search-lang-toggle {
    flex-direction: row;
    gap: 0.5rem;
  }
}
