/* Contact Us section styles */
.contactUs {
    text-align: center;
}

/* Full-width, half-height image with text centered on top */
.grad-cap {
    position: relative;
    width: 100%;
    height: 50vh;
    background-image: url('../Assets/middle2.jpg');
    background-size: cover;
    background-position: center;
}

/* Remove image tag display, since we're using a background image */
.grad-cap img {
    display: none;
}

/* Ensure content is centered */
.contact-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 2;
    color: #fff;
}

.contact-content h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    color: white;
}

.contact-content p {
    font-size: 1rem;
    color: #f0f0f0;
}

/* Optional overlay to improve text readability */
.grad-cap::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

/* Make the link for the header clickable */
.grad-cap a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    text-decoration: none;
}

/* Additional styles for contact info */
.contact-info {
    padding: 2rem 1rem;
}

.contact-info h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.contact-details {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1rem;
}

.contact-details div {
    flex: 1 1 300px;
    max-width: 300px;
    padding: 1.5rem;
    background-color: #f1f1f1;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.contact-details i {
    font-size: 1.5rem;
    color: #04a5e9;
    margin-bottom: 0.5rem;
}

/* FontAwesome icons for contact details */
.icon-map::before,
.icon-phone::before,
.icon-clock::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    display: inline-block;
    margin-right: 0.5rem;
}

.icon-map::before {
    content: "\f3c5";
}

.icon-phone::before {
    content: "\f095";
}

.icon-clock::before {
    content: "\f017";
}

.contact-info p {
    font-size: 1rem;
    color: #555;
}

.contact-info strong {
    font-size: 1.1rem;
    color: #333;
    display: block;
    margin-bottom: 0.5rem;
}

/* Media query for responsive design */
@media (max-width: 768px) {
    .contact-content h1 {
        font-size: 2rem;
    }

    .contact-content p {
        font-size: 0.9rem;
    }

    .contact-details div {
        flex-basis: 100%;
    }
}