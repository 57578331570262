body {
  margin: 0;
  font-family: 'Playfair Display', serif; /* Applying Playfair Display globally */
  -webkit-font-smoothing: antialiased; /* Smoothing for WebKit browsers */
  -moz-osx-font-smoothing: grayscale; /* Font smoothing for macOS */
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  /* Applying specific fonts for code elements */
}
