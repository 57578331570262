/* Banner container */
.banner-container {
  position: relative;
  width: 100%;
  height: 60vh; /* Default height for banner */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Banner item for image centering */
.banner-item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Ensures images cover the container without distortion */
.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Image covers the container */
  object-position: center; /* Center the image */
  transition: opacity 0.5s ease-in-out; /* Smooth transition for image changes */
}

/* Mobile view (screen width <= 768px) */
@media (max-width: 768px) {
  .banner-container {
    height: 50vh; /* Adjust height for mobile view */
  }
}

/* Tablet view (screen width between 769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .banner-container {
    height: 55vh; /* Adjust height for tablet view */
  }
}

/* Desktop and large screens (screen width >= 1025px) */
@media (min-width: 1025px) {
  .banner-container {
    height: 60vh; /* Full height for larger screens */
  }
}

