.registration-container {
  min-height: 100vh;
  background: #ffffff;
  padding: 2rem;
}
.custom-popup {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.25);
  opacity: 0;
  transform: translateY(-30px);
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Smooth bounce-in effect */
  z-index: 1000;
  max-width: 90%;
  min-width: 280px;
  background-color: #333;
  color: white;
  font-family: 'Arial', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-popup.visible {
  opacity: 1;
  transform: translateY(0);
}

.custom-popup p {
  margin: 0;
  font-size: 18px;
  line-height: 1.6;
  text-align: center;
  font-weight: bold;
}

/* Popup types */
.custom-popup.success {
  background-color: #4caf50;
  border-left: 5px solid #2e7d32;
  box-shadow: 0 8px 25px rgba(76, 175, 80, 0.6);
}

.custom-popup.error {
  background-color: #f44336;
  border-left: 5px solid #c62828;
  box-shadow: 0 8px 25px rgba(244, 67, 54, 0.6);
}

.custom-popup.warning {
  background-color: #ff9800;
  border-left: 5px solid #ef6c00;
  box-shadow: 0 8px 25px rgba(255, 152, 0, 0.6);
}

.custom-popup.info {
  background-color: #2196f3;
  border-left: 5px solid #1565c0;
  box-shadow: 0 8px 25px rgba(33, 150, 243, 0.6);
}

/* Hover effects */
.custom-popup:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3); /* Deepen the shadow */
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
  .custom-popup {
    top: 15px;
    right: 15px;
    left: 15px;
    padding: 15px 25px;
  }
  
  .custom-popup p {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .custom-popup {
    top: 10px;
    right: 10px;
    left: 10px;
    padding: 12px 18px;
  }
  
  .custom-popup p {
    font-size: 14px;
  }
}

.content-grid {
  max-width: 1152px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 1024px) {
  .content-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.process-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  transition: all 0.3s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-left: 30px;
  margin-right: 30px;
}

.section-title {
  font-size: 1.875rem;
  font-weight: 700;
  color: #1f2937;
  margin-bottom: 2rem;
  text-decoration: underline;
  


}

.process-step {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.5rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  transition: all 0.3s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.process-step:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.step-number {
  flex-shrink: 0;
}

.number-circle {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #3b82f6;
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-content {
  flex-grow: 1;
}

.step-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0.5rem;
}

.step-description {
  font-size: 0.875rem;
  color: #6b7280;
  line-height: 1.5;
}

.registration-card {
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-header {
  padding: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
  background-color: #f9fafb;
}

.card-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1f2937;
  margin: 0;
}

.card-content {
  padding: 1.5rem;
}

.form-number {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #f3f4f6;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

.hash-icon {
  color: #6b7280;
  font-size: 1.25rem;
}

.form-number-text {
  color: #374151;
  font-family: monospace;
}

.registration-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.required {
  color: #ef4444;
}

.form-input,
.form-select {
  width: auto;
  padding: 0.625rem 1rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  color: #1f2937;
  transition: all 0.3s;
}

.form-input::placeholder {
  color: #9ca3af;
}

.form-input:focus,
.form-select:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.form-select option {
  background-color: #ffffff;
  color: #1f2937;
}

.submit-button {
  width: 100%;
  background-color: #3b82f6;
  color: white;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
  border: none;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #2563eb;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(37, 99, 235, 0.2);
}

.check-icon {
  opacity: 0;
  transition: opacity 0.3s;
}

.submit-button:hover .check-icon {
  opacity: 1;
}

/* Add smooth scrolling to the page */
html {
  scroll-behavior: smooth;
}