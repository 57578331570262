.middle {
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 20px;
}
.main {
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.content-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

/* Adjust header section to take half the width */
.header {
  width: 50%;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: auto;
}

.image-section {
  display: flex;
  flex-direction: row; /* Stack images vertically */
  width: 50%; /* Take the remaining half */
  gap: 20px;
}

.image-left,
.image-right {
  width: 100%; /* Make images take full width of their container */
  height: 700px; /* Adjust height as needed */
  object-fit: cover;
  border-radius: 10px;
}

.text-content {
  flex: 1;
}

.header-title {
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: bold;
  color: #0040a9; /* Dark blue */
}

.header-subtitle {
  font-size: 1.25rem;
  margin-bottom: 20px;
}

.header-mission {
  font-size: 1rem;
  margin-bottom: 20px;
  line-height: 1.5;
}

.buttons {
  margin-bottom: 20px;
}

.btn {
  background-color: #04a5e9; /* Light blue */
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px 10px 0;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #0040a9; /* Dark blue */
}

.program-btn {
  background-color: #0040a9; /* Dark blue */
  color: white;
  border: none;
  padding: 12px 24px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.Middle-footer {
  background-color: #04a5e9; /* Light blue */
  color: white;
  padding: 20px;
  text-align: center;
}

.Middle-footer-stats {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  flex-wrap: wrap;
}

.stat-item h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.stat-item p {
  font-size: 1rem;
  color: white;
}

/* Responsive design for smaller tablets and phones */
@media (max-width: 480px) {
  .image-section {
    flex-direction: column;
    width: 100%;
  }

  .image-left,
  .image-right {
    width: 100%;
    height: 200px;
  }

  .header-title {
    font-size: 1.4rem;
  }

  .header-subtitle {
    font-size: 0.9rem;
  }

  .header-mission {
    font-size: 0.8rem;
  }

  .program-btn {
    font-size: 14px;
    padding: 10px 18px;
  }

  .Middle-footer-stats {
    flex-direction: column;
    text-align: center;
  }
}

/* Mobile view adjustments for screens below 480px */
@media (max-width: 540px) {
  .middle {
    flex-direction: column; /* Stack elements vertically */
    padding: 10px;
  }

  .main {
    flex-direction: column; /* Stack elements vertically */
    gap: 20px; /* Reduce the gap for mobile */
  }

  .header {
    width: 100%; /* Full width for mobile */
    padding-right: 0; /* Remove right padding */
  }

  .image-section {
    flex-direction: column; /* Stack images vertically */
    width: 100%; /* Full width for mobile */
    gap: 10px; /* Reduce the gap between images */
  }

  .image-left, 
  .image-right {
    width: 100%; /* Full width of mobile screen */
    height: 200px; /* Reduced height for better mobile display */
  }

  .header-title {
    font-size: 1.2rem; /* Smaller font size for mobile */
    margin-bottom: 15px;
  }

  .header-subtitle {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }

  .header-mission {
    font-size: 0.8rem;
    line-height: 1.4; /* Adjust line height for readability */
    margin-bottom: 15px;
  }

  .buttons {
    margin-bottom: 15px;
  }

  .btn {
    padding: 8px 15px;
    margin: 0 5px 5px 0;
    font-size: 0.9rem; /* Slightly smaller buttons */
  }

  .program-btn {
    font-size: 0.85rem;
    padding: 8px 16px;
  }

  .Middle-footer-stats {
    flex-direction: column; /* Stack stats vertically */
    padding: 10px;
    text-align: center;
  }

  .stat-item h2 {
    font-size: 1.5rem;
  }

  .stat-item p {
    font-size: 0.9rem;
  }
}
