.course-container {
  padding: 20px;
}

.course-custom-dropdown {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  backdrop-filter: blur(15px); /* Increased blur for a smoother glass effect */
  background-color: rgba(255, 255, 255, 0.15); /* More translucent background */
  border: 1px solid rgba(255, 255, 255, 0.5); /* Lighter border for glass effect */
  border-radius: 0.5rem; /* Rounded corners */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.course-dropdown-toggle {
  padding: 10px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.25); /* Slightly more opaque for contrast */
  border: 1px solid rgba(206, 212, 218, 0.6); /* Lighter border */
  border-radius: 0.5rem; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.course-dropdown-toggle:hover {
  background-color: rgba(255, 255, 255, 0.35); /* Hover effect for toggle */
}

.course-dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.85); /* More opaque for dropdown */
  border: 1px solid rgba(206, 212, 218, 0.5);
  border-radius: 0.5rem; /* Rounded corners */
  z-index: 100;
  backdrop-filter: blur(15px); /* Increased blur for dropdown */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Shadow for dropdown */
}

.course-search-bar {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid rgba(206, 212, 218, 0.6);
  border-radius: 0.5rem; /* Rounded corners */
  background-color: rgba(255, 255, 255, 0.5); /* Slightly more opaque */
  transition: border 0.3s; /* Smooth transition for focus effect */
}

.course-search-bar:focus {
  border: 1px solid rgba(0, 123, 255, 0.7); /* Focus border color */
  outline: none; /* Remove outline */
}

.course-options-list {
  max-height: 150px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0;
}

.course-option {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.course-option:hover, .course-option.selected {
  background-color: rgba(233, 236, 239, 0.6); /* Slightly more opaque on hover */
}

.course-card {
  margin-top: 20px;
  padding: 16px;
  border: 1px solid rgba(206, 212, 218, 0.6); /* Lighter border */
  border-radius: 0.5rem; /* Rounded corners */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* Slightly larger shadow for depth */
  background-color: rgba(255, 255, 255, 0.25); /* More translucent background */
  backdrop-filter: blur(10px); /* Keep a subtle blur effect for the card */
}

.course-card h3, .course-card h4 {
  margin: 0 0 10px;
  color: rgba(0, 0, 0, 0.9); /* Darker text for better readability */
}

.course-card p {
  margin: 0 0 10px;
  color: rgba(0, 0, 0, 0.7); /* Slightly darker for paragraph text */
}
