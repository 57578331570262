.contact {
    max-width: 1200px; /* Max width of the contact section */
    margin: auto; /* Center the section */
    padding: 20px; /* Padding around the section */
  }
  
  .imageContainer {
    display: flex; /* Flex layout for images */
    justify-content: space-between; /* Space between images */
    margin-bottom: 20px; /* Space below images */
  }
  
  .imageCard {
    position: relative; /* For overlay positioning */
    flex: 1; /* Flex-grow to fill space */
    margin: 0 10px; /* Margin between cards */
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* Clip overflow to make corners rounded */
    transition: transform 0.3s; /* Smooth scale effect */
  }
  
  .imageCard:hover {
    transform: scale(1.05); /* Scale up on hover */
  }
  
  .image {
    width: 100%; /* Full width of the card */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Remove bottom space in images */
  }
  
  .textBlock {
    position: absolute; /* Overlay on the image */
    bottom: 0; /* Align to the bottom */
    left: 0; /* Align to the left */
    right: 0; /* Align to the right */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    color: white; /* Text color */
    padding: 10px; /* Padding inside the text block */
    text-align: center; /* Center align text */
  }
  
  .contact-form-container {
    margin-top: 20px; /* Space above the form */
    padding: 30px; /* Padding around the form */
    background-color: #f9f9f9; /* Light background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Shadow for elevation */
  }
  
  .form-title {
    text-align: center; /* Center the title */
    margin-bottom: 20px; /* Space below the title */
    font-size: 2rem; /* Title font size */
    color: #333; /* Dark text color */
  }
  
  .form-group {
    margin-bottom: 20px; /* Space between form groups */
  }
  
  label {
    font-weight: bold; /* Bold labels */
    margin-bottom: 5px; /* Space below labels */
    display: block; /* Ensure labels take the full width */
    color: #555; /* Label color */
  }
  
  .form-input, .form-textarea {
    width: 100%; /* Full width */
    padding: 12px; /* Padding inside inputs */
    border: 1px solid #ccc; /* Border */
    border-radius: 5px; /* Rounded corners */
    font-size: 1rem; /* Font size */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle inset shadow */
    transition: border-color 0.3s; /* Transition for focus effect */
  }
  
  .form-input:focus, .form-textarea:focus {
    border-color: #007bff; /* Change border color on focus */
    outline: none; /* Remove default outline */
  }
  
  .form-textarea {
    min-height: 120px; /* Minimum height for textarea */
  }
  
  .submit-button {
    padding: 12px 20px; /* Padding inside button */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    background-color: #007bff; /* Button background color */
    color: white; /* Text color */
    font-size: 1rem; /* Button font size */
    cursor: pointer; /* Change cursor to pointer */
    transition: background-color 0.3s; /* Transition for hover effect */
  }
  
  .submit-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  