.container {
    width: 100%;
    max-width: auto;
    margin: auto;
    padding: 20px;
}

.container h1 {
    text-align: center;
    color: #0040a9;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
}

.card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex; /* Use flexbox for the card layout */
    flex-direction: column; /* Align items in a column */
}

.card img {
    width: 100%;
    height: 200px; /* Set a fixed height for all images */
    object-fit: cover; /* Maintain aspect ratio and cover the area */
}

.text-container {
    padding: 10px;
    flex-grow: 1; /* Allow text container to grow and take up space */
}

.text-container h2 {
    font-size: 1.2em;
    margin: 0;
    color: #555;
}

.text-container p {
    font-size: 0.9em;
    color: #777;
}
