/* Base styles */
.CampusLife {
  padding: 20px;
  font-family: Arial, sans-serif;
  color:rgb(31, 31, 150);
  cursor: pointer;
}

.campus {
  text-align: center;
  margin-bottom: 40px;
}

.campus h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.campus p {
  font-size: 1rem;
  color: #555;
 
}

/* Styling for the image container */
.CampusImage {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
}

.CampusImage div {
  text-align: center;
  margin: 10px;
}

/* Styling for the image container */
.CampusImage {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
}

.CampusImage div {
  text-align: center;
  margin: 10px;
}

.CampusImage img {
  max-width: 400px; /* Set a maximum width for images */
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for better visuals */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover transition */
}

/* Hover effect for images */
.CampusImage img:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* More shadow on hover */
}

/* Styling for the paragraph under images */
.CampusImage p {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  transition: color 0.3s ease; /* Smooth transition for hover */
}

/* Hover effect for text */
.CampusImage p:hover {
  color:rgb(5, 216, 231); /* Change text color on hover (can adjust to fit your theme) */
}

/* Media query for tablets and smaller devices */
@media (max-width: 768px) {
  .CampusImage {
    flex-direction: column; /* Stack images vertically */
    align-items: center; /* Center the images */
  }

  .CampusImage div {
    margin: 20px 0; 
  }

  .campus h2 {
    font-size: 1.75rem;
  }

  .campus p {
    font-size: 0.95rem;
  }
}

/* Media query for mobile devices (phones) */
@media (max-width: 480px) {
  .CampusLife {
    padding: 15px;
  }

  .CampusImage img {
    max-width: 100%; /* Make images full width on mobile */
    width: 100%;
  }

  .CampusImage div {
    margin: 15px 0; /* Smaller spacing between images */
  }

  .campus h2 {
    font-size: 1.5rem; /* Reduce heading size */
  }

  .campus p {
    font-size: 0.9rem; /* Smaller font for the description */
  }
}
