.partner-container {
  background-color: #FFFFFF;
  padding: 20px;
}

.text-container {
  text-align: center;
  margin-bottom: 30px;
}

.title {
  font-size: 16px;
  color: #0040a9; /* Dark blue */
  font-weight: bold;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 24px;
  color: #04a5e9; /* Light blue */
  font-weight: bold;
}

.logo-wrapper {
  overflow: hidden;
  width: 100%;
}

.logo-container {
  display: flex;
  width: fit-content;
  animation: scroll 20s linear infinite;
}

.logo {
  width: 200px;
  height: 60px;
  margin-right: 20px;
  object-fit: contain;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Pause animation on hover */
.logo-wrapper:hover .logo-container {
  animation-play-state: paused;
}

@media (max-width: 768px) {
  .logo {
    width: 150px;
  }
}
