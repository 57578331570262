.testimonial-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}

.testimonial-card {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 32px;
  gap: 48px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.testimonial-image-container {
  flex-shrink: 0;
  width: 400px;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
}

.testimonial-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
}

.testimonial-logo {
  height: 32px;
}

.logo {
  height: 100%;
  object-fit: contain;
}

.testimonial-quote {
  font-size: 24px;
  line-height: 1.5;
  color: #333333;
  margin: 0;
  font-weight: 400;
}

.testimonial-author {
  margin-top: 8px;
}

.author-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  margin: 0 0 4px 0;
}

.author-title {
  font-size: 16px;
  color: #666666;
  margin: 0;
}

/* Responsive Styling */
@media (max-width: 1024px) {
  .testimonial-card {
    padding: 24px;
    gap: 32px;
  }

  .testimonial-image-container {
    width: 300px;
    height: 300px;
  }

  .testimonial-quote {
    font-size: 22px;
  }

  .testimonial-content {
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .testimonial-card {
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 24px;
  }

  .testimonial-image-container {
    width: 256px;
    height: 256px;
  }

  .testimonial-quote {
    font-size: 20px;
    text-align: center;
  }

  .testimonial-content {
    gap: 16px;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .testimonial-container {
    padding: 20px;
  }

  .testimonial-card {
    padding: 20px;
    gap: 16px;
  }

  .testimonial-image-container {
    width: 200px;
    height: 200px;
  }

  .testimonial-quote {
    font-size: 18px;
  }

  .author-name {
    font-size: 18px;
  }

  .author-title {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .testimonial-card {
    padding: 16px;
    gap: 12px;
  }

  .testimonial-image-container {
    width: 150px;
    height: 150px;
  }

  .testimonial-quote {
    font-size: 16px;
  }

  .author-name {
    font-size: 16px;
  }

  .author-title {
    font-size: 12px;
  }
}

